import React , {useState} from "react";
import AniLink            from "gatsby-plugin-transition-link/AniLink"
import { FaAlignRight }   from "react-icons/fa";
import links              from "../../constants/links"
import socialIcons        from "../../constants/social-icons";
import logo               from "../../images/logo.png"
import './styles.scss'

const Navbar = () => 
{
    const [isOpen , setNav ] = useState(false);
    const toggleNav = () => 
    {
        setNav(isOpen => !isOpen)
    }
    return (
        <nav className='nav-bar'>
            <div className='nav-header'>
                <img src={logo} className='logo' alt="backroads logo" />
                <button type="button" className='logo-btn' onClick={toggleNav}>
                    <FaAlignRight className='icon' />
                 </button>
            </div>
            <div className={isOpen ? `nav-links` : `nav-links hide-nav`}>
                <ul>
                    {
                        links.map((item, index) => 
                        {
                            return (
                                <li key={index}>
                                    <AniLink swipe direction="down" hex="#AEECEE" to={item.path}>
                                        {item.text}
                                    </AniLink>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            
            <div className='nav-social-links'>
                {
                    socialIcons.map((item, index) => 
                    {
                        return(
                            <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                                {item.icon}
                            </a>
                        )
                    })
                }
            </div>
        
        </nav>
    )

}

export default Navbar