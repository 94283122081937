// that might be a cleaner way to proceed with the things 
// that i have to do 
const links = [
    {
        path : "/",
        text : "home",
    },
    {
        path : "/places",
        text : "places",
    },
    {
        path : "/blog",
        text : "blog",
    },
    {
        path : "/photos",
        text : "photos",
    },
    {
        path : "/contact",
        text : "contact",
    }
]
export default links
