import React from "react";
import {FaTwitterSquare ,  FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"

// ok so i think that i will just keep the website alone , 
// that is what I have to do i m sure that i can do that , 
// let s define an array and export it later on 

const socialLinks = [
    {
        icon : <FaFacebookSquare/> ,
        url : "https://facebook.com",
    },
    {
        icon : <FaTwitterSquare/> ,
        url : "https://twitter.com",
    },
    {
        icon : <FaInstagramSquare/> ,
        url : "https://Instagram.com",
    },
]

export default socialLinks








